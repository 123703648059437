
.box {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-top: 2px solid #DEE7F4;
}

.box:hover {
    background: #DEE7F4;
}

.box p {
    margin: 0;
}

.box .shcoolName{
    font-size: .9rem;
    text-wrap: wrap;
}

.box .deparmentName{
    font-size: .8rem;
    color: #727272;
}

.box .buttonArea {
    display: grid;
    grid-template-rows: repeat(1fr, 2);
    row-gap: 1rem;
}

.box .button {
    cursor: pointer;
    background-color: rgb(60, 60, 161);
    color: white;
    border-radius: 3px;
    border: none;
    padding: .3rem;
    font-size: 1rem;
    aspect-ratio: 1 / 1;
}

.box input {
    padding: .3rem;
    font-size: 1rem;
    aspect-ratio: 1 / 1;
    max-width: 20px;
    text-align: center;
}

.box .button:hover {
    background-color: rgb(49, 49, 142);
    color: rgb(219, 219, 219);
}